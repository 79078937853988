<template>
	<main>
		<div class="main">
			<div class="menu">
				<div @click="toMenu(item)" class="item" :class="query.type === item.type ? 'active' : ''" v-for="(item,index) in menu" :key="index" >
					<div class="tit" :class="item.icon">{{item.title}}</div>
				</div>
				<div class="item" :class="[query.type === 'setting' ? 'active' : '']" @click="toSetting">
					<div class="tit el-icon-setting">项目设置</div>
				</div>
			</div>
			<div class="content">
				<router-view :key="route_key"></router-view>
			</div>
		</div>
	</main>
</template>
<script>
import {project} from "@/api/sentry";

export default {
	data(){
		return {
			query:{},
			menu_path:"",
			menu_type:"",
			menu:[
				{title:"项目概览",icon:"el-icon-odometer"},
				{title:"错误事件",icon:"el-icon-warning-outline",type:"error"},
				{title:"接口事件",icon:"el-icon-link",type:"http"},
				{title:"资源事件",icon:"el-icon-paperclip",type:"resource"},
				{title:"未知事件",icon:"el-icon-document-remove",type:"unknown"},
				{title:"自定义上报",icon:"el-icon-tickets",type:"log"},
				{title:"节点监控",icon:"el-icon-tickets",type:"node"},
			],
		}
	},
	computed:{
		project(){
			return this.$store.state.sentry.project
		},
		route_path(){
			return this.$route.path;
		},
		route_key(){
			return this.$route.fullPath;
		}
	},
	created() {
		this.query = this.$route.query;
		this.menu_path = this.query.path || 'dashboard';
		this.menu_type = this.query.type || '';
	},
	mounted() {
		this.getProjectDetail();
	},
	methods:{
		getProjectDetail(){
			project.get({
				id:this.query.project_id
			}).then((res)=>{
				this.$store.commit('sentry/setProject',res.detail);
			})
		},
		toSetting(){
			this.$router.replace('/sentry/project/setting?type=setting&project_id='+this.query.project_id);
		},
		toMenu(item){
			let url = '/sentry/project/dashboard?project_id='+this.query.project_id;
			if(item.type){
				switch (item.type){
					case "node":
						url = "/sentry/project/node?project_id="+this.query.project_id+'&type='+item.type;;
						break;
					default:
						url = '/sentry/project/event?project_id='+this.query.project_id+'&type='+item.type;
						break;
				}
			}
			this.$router.replace(url);
		}
	},
	beforeDestroy() {
		this.$store.commit('sentry/setProject',{})
	}
}
</script>
<style lang="scss" scoped>
.main{display: flex;height:calc(100vh - 56px);
	.menu{width: 160px;height:100%;overflow: hidden;overflow-y: auto;box-shadow: 0 20px 20px 5px rgba(0,0,0,.1);
		.item{padding:18px 15px;cursor: pointer;transition: all .3s ease;
			.tit::before{margin-right: 3px;}
		}
		.item.active{background: $admin_main;color:#fff;transition: all .3s ease;}
	}
	.content{flex: 1;overflow: hidden;overflow-y: auto;padding: 0 15px;background: #efefef;}
}
</style>
